import React from 'react'

import PropTypes from 'prop-types'

import './footer-link.css'

const FooterLink = (props) => {
  return (
    <a href={props.link} className="footer-link-link">
      <div className="footer-link-container">
        <span className="footer-link-text">{props.label}</span>
      </div>
    </a>
  )
}

FooterLink.defaultProps = {
  label: 'Presto',
  link: '/#',
}

FooterLink.propTypes = {
  label: PropTypes.string,
  link: PropTypes.string,
}

export default FooterLink
